* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::-moz-selection { /* Code for Firefox */
    color: rgb(0, 0, 0);
    background: rgb(216, 177, 253);
  }
  
::selection {
    color: rgb(0, 0, 0);
    background: rgb(216, 177, 253);
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    display: none;
}

/* Track */
::-webkit-scrollbar-track {
    opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(121, 121, 121, 0.37);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(151, 151, 151, 0.37);
}

body {
    background-color: #000000;
    color: #f1f1f1;
    font-family: 'Inter';
    font-size: large;
    font-weight: 200;
    overflow-x: hidden;
}

.no-select {
    user-select: none;
}

.background {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: #000000;
    opacity: 1;
}

.background-texture {
    z-index: -2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: #000000;
    opacity: 0.17;
}

.background-gradient {
    z-index: -3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    background-image: linear-gradient(to bottom, #000000, #070707, #0e0e0e, #131313, #383838, #585858, #999999, #505050, #222222, #0e0e0e, #070707, #000000);
    /* background-image: linear-gradient(to bottom, #000000, #171717, #272727, #373737, #494949, #494949, #494949, #494949, #373737, #272727, #171717, #000000); */
    opacity: 1;
}

.background-gradient-color {
    z-index: -3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    /*background-image: linear-gradient(to bottom, #8892c2, #79739b, #655775, #4e3d52, #352632, #291c24, #1d1318, #100409, #0d0306, #090204, #040102, #000000);
    */
    /*background-image: linear-gradient(to bottom, #9dbccf, #89aecf, #7c9fce, #778eca, #7a7cc3, #8174c6, #8b6bc7, #9860c6, #a75fd5, #b65de4, #c759f2, #d953ff);
    */
    /*background-image: linear-gradient(to bottom, #1c1c1c, #282342, #34296a, #442b92, #5b27b9, #6521b6, #6e1bb3, #7612af, #651a85, #501f5f, #39203d, #1f1e1f);
    opacity: 0;*/
}

.canvas {
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

.nav {
    z-index: 5;
    width: 100vw;
    background-color: #000000;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 1rem 2rem;
    box-shadow: inset 0 -1px #1a1a1a;
}

.sticky {
    position: fixed;
    top: 0;
}

.title {
    font-family: "Lovan";
    font-size: xx-large;
    padding-left: 5rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 10rem;
    padding: 0 10rem;
    text-wrap: nowrap;
}

.nav a {
    color: inherit;
    text-decoration: none;
}

.content {
    position: relative;
    width: 100vw;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left-card {    
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-content {
    max-width: 32rem;
    width: 100%;
    line-height: 1.6;
    font-size: larger;
}

/* // CAROUSEL // */
.tag-list {
    margin: 0;
    padding-inline: 0;
    list-style: none;
}

.scroll-item {
    min-width: 5rem;
    height: 8rem;
    color: #dfdfdf;
    filter: drop-shadow(0 0.5rem 0.5rem rgb(0, 0, 0));
    flex-shrink: 0;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.scroll-item p {
    font-family: "Lovan";
    font-size: 150px;
}

.scroller {
    margin-top: 48vh;
    max-width: 50vw;
    overflow: hidden;
    -webkit-mask: linear-gradient(90deg, transparent, red 20%, red 80%, transparent);
    mask: linear-gradient(90deg, transparent, red 20%, red 80%, transparent);
}

.scroller__inner {
    width: max-content;
    padding-block: 1rem;
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    animation: scroll 60s linear infinite;
}

@keyframes scroll {
    to {
        transform: translate(calc(-50% - 0.5rem));
    }
}

/* // PLAYLIST OVERLAY // */

.playlist-overlay-frame {
    width: 100%;
    height: 100vh;
    padding-inline: 1rem;
    padding-block: 5%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: end;
    gap: 5%;
}

/* PLAYLIST */

.song {
    height: 95px;
    width: 100%;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    align-items: center;
    background-color: #1a1a1a00;
    border: none;
    color: white;
    text-align: left;
}

.song:hover {
    background-color: #8181811e;
    cursor: pointer;
}

.playlist {
    min-width: 420px;
    min-height: 55vh;
    max-height: 75vh;
    border-radius: 15px;
    padding-inline: 1rem;
    padding-top: 0.6rem;
    overflow-y: scroll;
    background-color: #1a1a1afa;
    box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.3);
}

.playlist-mobile {
    position: absolute;
    width: 100%;
    height: 100vh;
    bottom: 0;
    padding-inline: 0rem;
    padding-top: 1.5rem;
    margin-top: 1rem;
    background-color: rgba(26, 26, 26, 0.959);
    box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.3);
}

.playlist-mobile-scroll {
    position: absolute;
    width: 100%;
    height: 58%;
    top: 4rem;
    padding-inline:0.5rem;
    overflow-y: scroll;
    margin-top: 1rem;
}

/* NOW PLAYING */

.now-playing {
    min-width: 420px;
    min-height: 30vh;
    max-height: 50vh;
    margin-top: 1rem;
    margin-bottom: 25vh;
}

.now-playing-frame {
    max-width: 350px;
    border-radius: 15px;
    padding: 1.5rem;
    background-color: rgba(26, 26, 26, 0.9);
    overflow: hidden;
    box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.3);
}

.now-playing-mobile {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 150px;
    padding-inline: 0.5rem;
}

.now-playing-frame-mobile {
    border-radius: 8px;
    padding: 0rem;
    background-color: rgba(26, 26, 26, 0);
    box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

/* MEDIA CONTROLS */

.media-controls {
    width: 540px;
    min-width: 440px;
    height: 150px;
    border-radius: 25px;
    margin-bottom: 2rem;
    background-color: #1a1a1ad5;
    box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.media-controls-mobile {
    position: absolute;
    bottom: 0;
    width: 95%;
    height: 150px;
    border-radius: 25px;
    margin-bottom: 3rem;
    background-color: #1d1d1d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.media-button {
    background-color: rgba(255, 0, 0, 0);
    border: none;
    color: #ffffff;
}

.media-button:active {
    opacity: 0.5;
    transform: translateY(1px);
}

.media-button:hover {
    color: #c0c0c0;
    cursor: pointer;
}

.timeline {
    width: 100%;
    height: 1rem;
    display: flex;
    flex-wrap: nowrap;
    gap: 2rem;
    justify-content: center;
    align-items: end;
}

.scrubber-frame {
    width: 70%;
    height: 6px;
    border-radius: 25px;
    margin-top: 1.4rem;
    background-color: rgba(65, 65, 65, 0.822);
    overflow: hidden;
}

.scrubber {
    width: 0%;
    height: 100%;
    background-color: rgb(255, 255, 255);
}

.scrubber-time {
    width: 1rem;
    margin-bottom: -5px;
    font-size: small;
}

/* MEDIA QUERIES */
@media (max-width: 375px) {
    /* NAVBAR */
    .nav {
        gap: 0rem;
        padding: 1rem 0rem;
    }
    .title {
        font-size: large;
        padding-left: 2rem;
    }
    .nav ul {
        gap: 1.5rem;
        padding: 0 2rem;
        font-size: x-small;
    }

    /* CAROUSEL */
    .scroller {
        margin-top: 0rem;
        max-width: 90vw;
    }
    .scroll-item p {
        font-size: 100px;
    }

    /* ABOUT ME OVERLAY */
    .left-card {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-content {
        max-width: 22rem;
        width: 100%;
        font-size: small;
        padding-inline: 2rem;
        padding-top: 13rem;
    }
}
@media (375px < width <= 475px) {
    /* NAVBAR */
    .nav {
        gap: 2rem;
        padding: 1rem 0rem;
    }
    .title {
        font-size: large;
        padding-left: 2rem;
    }
    .nav ul {
        gap: 2rem;
        padding: 0 2rem;
        font-size: x-small;
    }

    /* CAROUSEL */
    .scroller {
        margin-top: 0rem;
        max-width: 90vw;
    }
    .scroll-item p {
        font-size: 100px;
    }

    /* ABOUT ME OVERLAY */
    .left-card {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-content {
        max-width: 22rem;
        width: 100%;
        font-size: small;
        padding-inline: 2rem;
        padding-top: 13rem;
    }
}
@media (475px < width <= 640px) {}
@media (475px < width <= 768px) {
    /* NAVBAR */
    .nav {
        gap: 0rem;
        padding: 1rem 0rem;
    }
    .title {
        font-size: x-large;
        padding-left: 4rem;
    }
    .nav ul {
        gap: 3rem;
        padding: 0 4rem;
        font-size: small;
    }

    /* CAROUSEL */
    .scroller {
        margin-top: 0rem;
        max-width: 80vw;
    }
    .scroll-item p {
        font-size: 100px;
    }

    /* ABOUT ME OVERLAY */
    .left-card {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-content {
        max-width: 22rem;
        width: 100%;
        font-size: medium;
        padding-inline: 2rem;
        padding-top: 13rem;
    }
}
@media (768px < width <= 1024px) {
    /* NAVBAR */
    .nav {
        padding: 1rem 0rem;
    }
    .title {
        font-size: x-large;
        padding-left: 5rem;
    }
    .nav ul {
        gap: 5rem;
        padding: 0 5rem;
        font-size: medium;
    }

    /* CAROUSEL */
    .scroller {
        max-width: 70vw;
    }
    .scroll-item p {
        font-size: 100px;
    }

    /* ABOUT ME OVERLAY */
    .left-card {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-content {
        width: 100%;
        font-size: large;
        padding-inline-start: 2rem;
    }

    /* PLAYLIST OVERLAY */
    .playlist-overlay-frame {
        gap: 3%;
    }
    .media-controls {
        min-width: 340px;
    }
    .timeline {
        gap: 1.5rem;
    }
    .playlist {
        min-width: 300px;
    }
    .now-playing {
        min-width: 200px;
    }
    .now-playing-frame {
        min-width: 200px;
    }
}
@media (1024px < width <= 1280px) {
    /* NAVBAR */
    .nav ul {
        gap: 5rem;
        padding: 0 5rem;
    }

    /* PLAYLIST OVERLAY */
    .playlist-overlay-frame {
        gap: 3%;
    }
    .media-controls {
        min-width: 340px;
    }
    .timeline {
        gap: 1.5rem;
    }
    .playlist {
        min-width: 300px;
    }
    .now-playing {
        min-width: 200px;
    }
    .now-playing-frame {
        min-width: 200px;
    }
}
@media (1024 < width <= 1440px) {

    /* PLAYLIST OVERLAY */
    .timeline {
        gap: 1.5rem;
    }
    .playlist {
        min-width: 300px;
    }
    .now-playing {
        min-width: 300px;
    }
    .now-playing-frame {
        min-width: 300px;
    }
}